// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main{
    display: flex;
    flex-direction: column;
    background-color:white;
    overflow: auto; 
    width:100%;
   
}



.body {
  padding: 10px;
  line-height: 30px;
  flex: 1 1; /* Take remaining space next to the sidebar */
  max-height: 70vh; /* Full height of the viewport */
 /* Enable vertical scrolling */
}

  

  
 `, "",{"version":3,"sources":["webpack://./src/components/layout/Main.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,sBAAsB;IACtB,cAAc;IACd,UAAU;;AAEd;;;;AAIA;EACE,aAAa;EACb,iBAAiB;EACjB,SAAO,EAAE,6CAA6C;EACtD,gBAAgB,EAAE,gCAAgC;CACnD,8BAA8B;AAC/B","sourcesContent":[".main{\r\n    display: flex;\r\n    flex-direction: column;\r\n    background-color:white;\r\n    overflow: auto; \r\n    width:100%;\r\n   \r\n}\r\n\r\n\r\n\r\n.body {\r\n  padding: 10px;\r\n  line-height: 30px;\r\n  flex: 1; /* Take remaining space next to the sidebar */\r\n  max-height: 70vh; /* Full height of the viewport */\r\n /* Enable vertical scrolling */\r\n}\r\n\r\n  \r\n\r\n  \r\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
