// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Member Details Container */
.member-details {
    display: flex;
    flex-direction: column; /* Stack member cards vertically */
    gap: 1rem; /* Add space between member cards */
    padding: 1rem; /* Padding around the container */
  }
  
  /* Individual Member Card */
  .member-card {
    background-color: #f9f9f9; /* Light background for cards */
    border: 1px solid #ddd; /* Light border around cards */
    border-radius: 5px; /* Rounded corners */
    padding: 1rem; /* Padding inside cards */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Slight shadow for depth */
    line-height: 30%;
  }
  
  /* Title Bold Class */
  .titleBold {
    font-weight: bold; /* Make title text bold */
    margin-bottom: 0.5rem; /* Space below the title */
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/LandingPage.css"],"names":[],"mappings":"AAAA,6BAA6B;AAC7B;IACI,aAAa;IACb,sBAAsB,EAAE,kCAAkC;IAC1D,SAAS,EAAE,mCAAmC;IAC9C,aAAa,EAAE,iCAAiC;EAClD;;EAEA,2BAA2B;EAC3B;IACE,yBAAyB,EAAE,+BAA+B;IAC1D,sBAAsB,EAAE,8BAA8B;IACtD,kBAAkB,EAAE,oBAAoB;IACxC,aAAa,EAAE,yBAAyB;IACxC,wCAAwC,EAAE,4BAA4B;IACtE,gBAAgB;EAClB;;EAEA,qBAAqB;EACrB;IACE,iBAAiB,EAAE,yBAAyB;IAC5C,qBAAqB,EAAE,0BAA0B;EACnD","sourcesContent":["/* Member Details Container */\r\n.member-details {\r\n    display: flex;\r\n    flex-direction: column; /* Stack member cards vertically */\r\n    gap: 1rem; /* Add space between member cards */\r\n    padding: 1rem; /* Padding around the container */\r\n  }\r\n  \r\n  /* Individual Member Card */\r\n  .member-card {\r\n    background-color: #f9f9f9; /* Light background for cards */\r\n    border: 1px solid #ddd; /* Light border around cards */\r\n    border-radius: 5px; /* Rounded corners */\r\n    padding: 1rem; /* Padding inside cards */\r\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Slight shadow for depth */\r\n    line-height: 30%;\r\n  }\r\n  \r\n  /* Title Bold Class */\r\n  .titleBold {\r\n    font-weight: bold; /* Make title text bold */\r\n    margin-bottom: 0.5rem; /* Space below the title */\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
