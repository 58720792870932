// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Header.css */

.header {
  background-color: lightblue;
  color: black;
  padding: 1rem; /* Adjust padding as needed */
  display: flex;
  align-items: center; /* Vertically center items */
  justify-content: space-between; /* Space between the left and right items */
  width: 100%;
}

.header h3 {
  margin: 0;
  font-size: 1.5rem; /* Adjust font size as needed */
}

.header h3 {
  margin:0;
  font-size: 1rem;
  margin-right: 20px; /* Adjust font size as needed */
}

@media screen and (max-width: 768px) {
  .header {
    font-size: 0.8rem; /* Adjust font size for smaller screens */
    padding: 0.5rem; /* Adjust padding for smaller screens */
  }

  .header h2 {
    font-size: 1.2rem; /* Adjust font size for h2 on smaller screens */
  }

  .header h4 {
    font-size: 0.8rem; /* Adjust font size for h4 on smaller screens */
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/layout/Header.css"],"names":[],"mappings":"AAAA,eAAe;;AAEf;EACE,2BAA2B;EAC3B,YAAY;EACZ,aAAa,EAAE,6BAA6B;EAC5C,aAAa;EACb,mBAAmB,EAAE,4BAA4B;EACjD,8BAA8B,EAAE,2CAA2C;EAC3E,WAAW;AACb;;AAEA;EACE,SAAS;EACT,iBAAiB,EAAE,+BAA+B;AACpD;;AAEA;EACE,QAAQ;EACR,eAAe;EACf,kBAAkB,EAAE,+BAA+B;AACrD;;AAEA;EACE;IACE,iBAAiB,EAAE,yCAAyC;IAC5D,eAAe,EAAE,uCAAuC;EAC1D;;EAEA;IACE,iBAAiB,EAAE,+CAA+C;EACpE;;EAEA;IACE,iBAAiB,EAAE,+CAA+C;EACpE;AACF","sourcesContent":["/* Header.css */\r\n\r\n.header {\r\n  background-color: lightblue;\r\n  color: black;\r\n  padding: 1rem; /* Adjust padding as needed */\r\n  display: flex;\r\n  align-items: center; /* Vertically center items */\r\n  justify-content: space-between; /* Space between the left and right items */\r\n  width: 100%;\r\n}\r\n\r\n.header h3 {\r\n  margin: 0;\r\n  font-size: 1.5rem; /* Adjust font size as needed */\r\n}\r\n\r\n.header h3 {\r\n  margin:0;\r\n  font-size: 1rem;\r\n  margin-right: 20px; /* Adjust font size as needed */\r\n}\r\n\r\n@media screen and (max-width: 768px) {\r\n  .header {\r\n    font-size: 0.8rem; /* Adjust font size for smaller screens */\r\n    padding: 0.5rem; /* Adjust padding for smaller screens */\r\n  }\r\n\r\n  .header h2 {\r\n    font-size: 1.2rem; /* Adjust font size for h2 on smaller screens */\r\n  }\r\n\r\n  .header h4 {\r\n    font-size: 0.8rem; /* Adjust font size for h4 on smaller screens */\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
