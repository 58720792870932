// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-menu {
  list-style-type: none;
  padding: 0;
}

.nav-item {
  margin-bottom: 10px;
}

.sub-menu {
  list-style-type: none;
  padding-left: 20px;
}

.sub-menu .nav-item {
  margin-bottom: 5px;
}

.bi {
  margin-right: 5px;
}
/* People.css */

/* Overall container */
.container {
max-width: 800px;
margin: 0 auto;
}

/* Table styling */
table {
width: 100%;
border-collapse: collapse;
margin-bottom: 20px;
}

table th {

padding: 50px;
text-align: left;
}

table td {
border: 1px solid #ddd;
padding: 10px;
width: 100px;
}

/* Advisor, External Advisor, Members container */
.section-container {
margin-bottom: 80px;

}

/* Faculty container */
.faculty {
display: flex;
align-items: center;
border: 51px solid #ccc;
padding: 20px;
}
.h4{
padding-left: 20px;
}

.titleBold{
font-weight: bold;
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;AACA,eAAe;;AAEf,sBAAsB;AACtB;AACA,gBAAgB;AAChB,cAAc;AACd;;AAEA,kBAAkB;AAClB;AACA,WAAW;AACX,yBAAyB;AACzB,mBAAmB;AACnB;;AAEA;;AAEA,aAAa;AACb,gBAAgB;AAChB;;AAEA;AACA,sBAAsB;AACtB,aAAa;AACb,YAAY;AACZ;;AAEA,iDAAiD;AACjD;AACA,mBAAmB;;AAEnB;;AAEA,sBAAsB;AACtB;AACA,aAAa;AACb,mBAAmB;AACnB,uBAAuB;AACvB,aAAa;AACb;AACA;AACA,kBAAkB;AAClB;;AAEA;AACA,iBAAiB;AACjB","sourcesContent":[".nav-menu {\r\n  list-style-type: none;\r\n  padding: 0;\r\n}\r\n\r\n.nav-item {\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.sub-menu {\r\n  list-style-type: none;\r\n  padding-left: 20px;\r\n}\r\n\r\n.sub-menu .nav-item {\r\n  margin-bottom: 5px;\r\n}\r\n\r\n.bi {\r\n  margin-right: 5px;\r\n}\r\n/* People.css */\r\n\r\n/* Overall container */\r\n.container {\r\nmax-width: 800px;\r\nmargin: 0 auto;\r\n}\r\n\r\n/* Table styling */\r\ntable {\r\nwidth: 100%;\r\nborder-collapse: collapse;\r\nmargin-bottom: 20px;\r\n}\r\n\r\ntable th {\r\n\r\npadding: 50px;\r\ntext-align: left;\r\n}\r\n\r\ntable td {\r\nborder: 1px solid #ddd;\r\npadding: 10px;\r\nwidth: 100px;\r\n}\r\n\r\n/* Advisor, External Advisor, Members container */\r\n.section-container {\r\nmargin-bottom: 80px;\r\n\r\n}\r\n\r\n/* Faculty container */\r\n.faculty {\r\ndisplay: flex;\r\nalign-items: center;\r\nborder: 51px solid #ccc;\r\npadding: 20px;\r\n}\r\n.h4{\r\npadding-left: 20px;\r\n}\r\n\r\n.titleBold{\r\nfont-weight: bold;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
